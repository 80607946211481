import moment from 'moment-timezone';

export const createApiAction = (type: any) => ({
  request: (payload: any) => ({ type, payload }),
  success: (payload: any) => ({ type: `${type}_SUCCESS`, payload }),
  failure: (error: any) => ({ type: `${type}_FAILURE`, error }),
  savePayload: (payload: any) => ({ type: `${type}_PAYLOAD`, payload }),
});

const todayValue = new Date();
export const today = Date.parse(new Date().toString());
export const todayFirstTime = Math.trunc(todayValue.setHours(0, 0, 0) / 1000);
export const todayLastTime = moment(todayValue).set({ hours: 23, minutes: 30, seconds: 0, milliseconds: 0 });
export const yesterday = Date.parse(new Date(todayValue.getTime() - 24 * 60 * 60 * 1000).toString());
export const lastWeekTime = moment().subtract(7, 'days').startOf('day').toDate();
export const tomorrow = Date.parse(new Date(todayValue.getTime() + 24 * 60 * 60 * 1000).toString());
export const lastWeek = Date.parse(moment().subtract(7, 'days').format('LLL'));
export const now = moment().toISOString();

export const getTimestampOf = (date: Date) => {
  return date.getTime() / 1000;
};

export const getFormatDateTimeStamp = (date: string | number, format: string) => {
  return moment(Number(date) * 1000).tz('Europe/London').format(format);
};

export const getHoursMinutesAndSeconds = (time: number) => {
  const h = Math.floor(time / 3600);
  const m = Math.floor((time % 3600) / 60);
  const s = Math.floor((time % 3600) % 60);

  const hDisplay = h > 0 ? h + (h === 1 ? ' hour' : ' hours') : '';
  const mDisplay = m > 0 ? m + (m === 1 ? ' minute' : ' minutes') : '';
  const sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : '';

  if (hDisplay) {
    return `${hDisplay}, ${mDisplay}, ${sDisplay}`;
  }
  if (!hDisplay && mDisplay) {
    return `${mDisplay}, ${sDisplay}`;
  }
  if (!hDisplay && !mDisplay && sDisplay) {
    return sDisplay;
  }
  return '0 seconds';
};

export const getHoursMinutesAndSecondsShorthand = (time: number) => {
  const h = Math.floor(time / 3600);
  const m = Math.floor((time % 3600) / 60);
  const s = Math.floor((time % 3600) % 60);

  const hDisplay = h > 0 ? h + (h === 1 ? ' H' : ' H') : '';
  const mDisplay = m > 0 ? m + (m === 1 ? ' M' : ' M') : '';
  const sDisplay = s > 0 ? s + (s === 1 ? ' S' : ' S') : '';

  if (hDisplay && mDisplay && sDisplay) {
    return `${hDisplay} ${mDisplay} ${sDisplay}`;
  }
  if (!hDisplay && mDisplay && sDisplay) {
    return `${hDisplay} ${mDisplay}`;
  }
  if (!hDisplay && !mDisplay && sDisplay) {
    return hDisplay;
  }
  return '0 S';
};

export const getHoursMinutesAndSecondsShorthandForAreaOfInterestTable = (time: number) => {
  const h = Math.floor(time / 3600);
  const m = Math.floor((time % 3600) / 60);
  const s = Math.floor((time % 3600) % 60);

  return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
};

export const getHoursAndMinutes = (time: number) => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor(time / 60);
  return `${hours} H & ${minutes} M`;
};

export const getMinutes = (time: number) => {
  const h = Math.floor(time / 3600);
  const m = Math.floor((time % 3600) / 60);
  const s = Math.floor((time % 3600) % 60);

  if (h > 0 && m > 0 && s > 0) {
    return `${h} H ${m} M`;
  }
  if (!(h > 0) && m > 0 && s > 0) {
    return `${m} M ${s} S`;
  }
  if (!(h > 0) && !(m > 0) && s > 0) {
    return `${s} S`;
  }
  return 'error at function';
};

export const getMinutesAndSeconds = (time: number) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.trunc(time - minutes * 60);
  return `${minutes} M & ${seconds} S`;
};

export const getSimpleMinutesAndSeconds = (time: number) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.trunc(time - minutes * 60);
  if (Number.isNaN(minutes) || Number.isNaN(seconds)) {
    return time;
  }
  return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
};

export const convertOneHourPercentageToMinutesAndSeconds = (percentage: number) => {
  return getSimpleMinutesAndSeconds(percentage * 3600);
};

export const round = (value: number, precision: number) => {
  const multiplier = 10 ** (precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

export const getMinutesAndHoursLabels = (seconds: number) => {
  const hours = seconds / 3600;
  if (hours >= 1) {
    return `${round(hours, 1)} Hours Or Less`;
  }

  const minutes = seconds / 60;
  if (minutes >= 1) {
    return `${round(minutes, 0)} Mins Or Less`;
  }

  return `${round(seconds, 0)} Secs Or Less`;
};

export const convertToMinutes = (value: number, seriesName: string, requestInterval: string) => {
  if (seriesName === 'store average time' || seriesName === 'store average department time') {
    if (requestInterval === '1h' || requestInterval === '1d') {
      const minutes = value / 60;
      return `${Math.trunc(minutes)} M`;
    }
    const hours = value / 3600;
    return `${Math.trunc(hours)} H`;
  }
  return value;
};

export const convertAsMniutes = (time: string) => {
  return moment.duration(moment(time, 'HH:mm').format('HH:mm')).asMinutes();
};

export const convertIntervalDateDays = (startDate: string, endDate: string) => {
  if (startDate && endDate) {
    const start = moment(new Date(startDate), 'YYYY-MM-DD', true);
    const end = moment(new Date(endDate), 'YYYY-MM-DD');

    const newStartDate = start.isValid() ? start : moment(new Date(Number(startDate)), 'YYYY-MM-DD', true);
    const newEndDate = end.isValid() ? end : moment(new Date(Number(endDate)), 'YYYY-MM-DD', true);

    const day = Math.trunc(moment.duration(newEndDate.diff(newStartDate)).asDays());

    if (day <= 1) {
      return 'HoH';
    }
    if (day > 1 && day <= 7) {
      return 'DoD';
    }
    if (day >= 7 && day <= 30) {
      return 'WoW';
    }
    if (day > 30) {
      return 'MoM';
    }
    return '';
  }
  return 'empty date';
};

export const verifyIfMinutesOurHours = (str: string) => {
  const time = str.split(':');
  const hours = time[0];
  return Number(hours) > 0 ? 'hrs' : 'min';
};

export const convertSecondsToHMS = (time: number) => {
  const hours = Math.floor(moment.duration(time, 'seconds').asHours());
  const minutes = moment.duration(time, 'seconds').minutes();
  const seconds = moment.duration(time, 'seconds').seconds();
  if (hours > 0) {
    return `${hours.toString()}h ${minutes.toString().padStart(2, '0')}m ${seconds
      .toString()
      .padStart(2, '0')}s`;
  }

  if (minutes > 0) {
    return `${minutes.toString()}m ${seconds.toString().padStart(2, '0')}s`;
  }

  return `${seconds.toString()}s`;
};

export const downloadCSV = function (data: string, filename: string) {
  const blob = new Blob([data], { type: 'text/csv' });

  const url = window.URL.createObjectURL(blob);

  const a = document.createElement('a');

  a.setAttribute('href', url);

  a.setAttribute('download', `${filename}.csv`);

  a.click();
};

// export const getDoDStartEndDateAuto = (start: number, end: number) => {
//   const startDate = new Date(start * 1000);
//   const endDate = new Date(end * 1000);
//   const interval = endDate.getTime() - startDate.getTime();
//   const days = Math.floor(interval / (1000 * 60 * 60 * 24)) + 1;
//   const startDateAuto = getTimestampOf(new Date(startDate.getTime() - days * 24 * 60 * 60 * 1000));
//   const endDateAuto = getTimestampOf(new Date(endDate.getTime() - days * 24 * 60 * 60 * 1000));
//   return { startDateAuto, endDateAuto };
// };

export const getDaysIntervalByDate = (start: number, end: number) => {
  const startDate = new Date(start * 1000);
  const endDate = new Date(end * 1000);
  const interval = endDate.getTime() - startDate.getTime();
  const days = Math.floor(interval / (1000 * 60 * 60 * 24));
  return days;
};

export const getIntervalByDate = (start: number, end: number) => {
  const daysInterval = getDaysIntervalByDate(start, end);
  if (daysInterval <= 1) {
    return '1h';
  }
  if (daysInterval > 1 && daysInterval <= 7) {
    return '1d';
  }
  if (daysInterval >= 7 && daysInterval <= 30) {
    return '1w';
  }
  if (daysInterval > 30) {
    return '1m';
  }
  return '1d';
};

/**
 * Returns the difference between two dates in days or months
 * @param startDate a date in unix timestamp format
 * @param endDate a date in unix timestamp format
 * @param type the type can be days or months
 * @returns a number with the difference between the dates in days or months
 */
export const differenceBetweenTwoDates = (startDate: number, endDate: number, type: 'days' | 'months') => {
  const start = moment.unix(startDate);
  const end = moment.unix(endDate);
  return end.diff(start, type);
};

export const numberFormat = (value: string | number) => {
  return Intl.NumberFormat('en-IN').format(Number(value));
};
