import React from 'react';
import cx from 'classnames';
import { Typography } from 'src/components/Typography';

export interface TableProps {
  cols: {
    title: string;
    field: string;
    order: boolean;
    // eslint-disable-next-line no-unused-vars
    customCol?: (rowData: unknown, idxRow: number) => React.ReactNode;
    alignment?: 'start' | 'center' | 'end';
  }[];
  rows: any[];
  className?: string;
  hoverByCell?: boolean;
  pointer?: boolean;
  bodyClassName?: string;
  dataTotal?: [];
}

const HIGHLIGHTED_ROW_NAME = 'highlightedRow';

export const Table = ({ cols, rows, className, hoverByCell, pointer, bodyClassName, dataTotal }: TableProps) => {
  const renderDataTotal = () => {
    return (
      dataTotal && (
        <tr className="table__simple__row table__simple__bottom">
          {dataTotal.map((v: any, i: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <td className="table__simple__row-item bg-violet-200" key={i}>
              {/* <div className={cx({ 'table__first-item': i === 0 })}> */}
              <div className="text-violet-800 font-semibold text-sm p-2 text-neutral-800">
                {v}
              </div>
            </td>
          ))}
        </tr>
      )
    );
  };

  return (
    <table className={cx('w-full', className)}>
      <thead className="border-b-2 border-b-neutral-100 ">
        <tr>
          {cols.map((col) => (
            <Typography
              variant="caption-3"
              color="neutral-600"
              tag="td"
              className={cx('px-2 py-1', {
                'text-center': col.alignment === 'center',
                'text-left': col.alignment === 'start',
                'text-right': col.alignment === 'end',
              })}
              key={col.title}
            >
              {col.title}
            </Typography>
          ))}
        </tr>
      </thead>
      <tbody className={bodyClassName || ''}>
        {rows.map((row, idxRow) => {
          const isHighlightedRow = !!row[HIGHLIGHTED_ROW_NAME];

          return (
            <tr
              className={cx('w-full transition-colors duration-100', {
                'bg-transparent': !isHighlightedRow,
                'hover:bg-primary-super-light': !hoverByCell,
                'bg-extra-super-light': isHighlightedRow,
                'cursor-pointer': pointer,
              })}
              key={JSON.stringify(row)}
            >
              {cols.map((col) => {
                if (col.customCol) {
                  return (
                    <td
                      className={cx('', {
                        'hover:bg-primary-super-light': hoverByCell,
                      })}
                      key={JSON.stringify({ ...row, idxRow, col })}
                    >
                      {col.customCol(row, idxRow)}
                    </td>
                  );
                }

                const item = row[col.field];

                return (
                  <Typography
                    variant="caption-2"
                    tag="td"
                    className={cx('p-2', {
                      'font-bold text-extra-dark': isHighlightedRow,
                      'text-neutral-800': !isHighlightedRow,
                      'hover:bg-primary-super-light': hoverByCell,
                      'text-center': col.alignment === 'center',
                      'text-left': col.alignment === 'start',
                      'text-right': col.alignment === 'end',
                    })}
                    key={JSON.stringify({ ...item, ...col })}
                  >
                    {item}
                  </Typography>
                );
              })}
            </tr>
          );
        })}
        {dataTotal && renderDataTotal()}
      </tbody>
    </table>
  );
};

export default Table;
