import { axiosJsonInstance } from 'src/store/api';
import { isEmptyOrUndefined } from 'src/utils/isUndefined';

export default {
  async getStoreAreas(storeId: any, startDate: any, endDate: any, genderFilter: any, ageFilter: any) {
    return axiosJsonInstance.get('tills/', {
      params: {
        store_id: storeId,
        start_date: startDate,
        end_date: endDate,
        gender: !isEmptyOrUndefined(genderFilter) ? genderFilter : '',
        age_range: !isEmptyOrUndefined(ageFilter) ? ageFilter : '',
      },
    });
  },

  async getDepartmentAreas(storeId: any, departmentId: any, startDate: any, endDate: any, genderFilter: any, ageFilter: any) {
    return axiosJsonInstance.get('areas/', {
      params: {
        store_id: storeId,
        department_id: departmentId,
        start_date: startDate,
        end_date: endDate,
        gender: !isEmptyOrUndefined(genderFilter) ? genderFilter : '',
        age_range: !isEmptyOrUndefined(ageFilter) ? ageFilter : '',
      },
    });
  },

  async getCameraAreas(storeId: any, departmentId: any, cameraId: any, startDate: any, endDate: any, genderFilter: any, ageFilter: any) {
    return axiosJsonInstance.get('areas/', {
      params: {
        store_id: storeId,
        department_id: departmentId,
        camera_id: cameraId,
        start_date: startDate,
        end_date: endDate,
        area_type: 'ALL',
        gender: !isEmptyOrUndefined(genderFilter) ? genderFilter : '',
        age_range: !isEmptyOrUndefined(ageFilter) ? ageFilter : '',
      },
    });
  },

  async getDwellAnalysis(storeId: any, departmentId: any, cameraId: any, startDate: any, endDate: any, genderFilter: any, ageFilter: any, thresholdFilter: any) {
    return axiosJsonInstance.get('areas/', {
      params: {
        store_id: storeId,
        department_id: departmentId,
        camera_id: cameraId,
        start_date: startDate,
        end_date: endDate,
        area_type: 'DA',
        gender: !isEmptyOrUndefined(genderFilter) ? genderFilter : '',
        age_range: !isEmptyOrUndefined(ageFilter) ? ageFilter : '',
        threshold_second: thresholdFilter,
      },
    });
  },

  async getAreasOfInterest(storeId: any, departmentId: any, cameraId: any, startDate: any, endDate: any, genderFilter: any, ageFilter: any, thresholdFilter: any) {
    return axiosJsonInstance.get('areas/', {
      params: {
        store_id: storeId,
        department_id: departmentId,
        camera_id: cameraId,
        start_date: startDate,
        end_date: endDate,
        area_type: 'AI',
        gender: !isEmptyOrUndefined(genderFilter) ? genderFilter : '',
        age_range: !isEmptyOrUndefined(ageFilter) ? ageFilter : '',
        threshold_second: thresholdFilter,
      },
    });
  },

  async getOutsideTraffic(storeId: any, departmentId: any, cameraId: any, startDate: any, endDate: any, genderFilter: any, ageFilter: any) {
    return axiosJsonInstance.get('areas/', {
      params: {
        store_id: storeId,
        department_id: departmentId,
        camera_id: cameraId,
        start_date: startDate,
        end_date: endDate,
        area_type: 'OT',
        gender: !isEmptyOrUndefined(genderFilter) ? genderFilter : '',
        age_range: !isEmptyOrUndefined(ageFilter) ? ageFilter : '',
      },
    });
  },

  async postAreas(storeId: any, departmentId: any, cameraId: any, data: any) {
    return axiosJsonInstance.post('areas/', {
      store_id: storeId,
      department_id: departmentId,
      camera_id: cameraId,
      data,
    });
  },

  async deleteArea(areaId: any) {
    return axiosJsonInstance.delete(`area/${areaId}/`);
  },

  async archiveArea(brandId: number, areaId: number) {
    return axiosJsonInstance.delete('areas/', {
      params: {
        brand_id: brandId,
        area_id: areaId,
      },
    });
  },

  async getArchivedAreas(storeId: number, departmentId: number, cameraId: number, startDate: number, endDate: number) {
    return axiosJsonInstance.get('areas/', {
      params: {
        store_id: storeId,
        department_id: departmentId,
        camera_id: cameraId,
        start_date: startDate,
        end_date: endDate,
        list_only_archived: 'True',
        area_type: 'ALL',
      },
    });
  },

  async getAreaDetails(areaId: any, startDate: any, endDate: any, genderFilter: any, ageFilter: any, thresholdFilter: any) {
    return axiosJsonInstance.get('areas/', {
      params: {
        area_id: areaId,
        start_date: startDate,
        end_date: endDate,
        area_type: 'ALL',
        gender: !isEmptyOrUndefined(genderFilter) ? genderFilter : '',
        age_range: !isEmptyOrUndefined(ageFilter) ? ageFilter : '',
        threshold_second: !isEmptyOrUndefined(thresholdFilter) ? thresholdFilter : '',
      },
    });
  },

  async getAreas(storeId: any, cameraId: any, areaId: any, areaType: any) {
    return axiosJsonInstance.get('area-info/', {
      params: {
        store_id: storeId,
        camera_id: cameraId,
        area_id: areaId,
        area_type: areaType,
      },
    });
  },
};
