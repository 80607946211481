import PlotProps from 'src/components/plotly/types';
import Plot from 'src/components/plotly';
import { IHeatMap } from 'src/interfaces/graph.interface';
import { modeBarButtonsToRemove } from 'src/utils/constants';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

const HeatMapCalendarBusiestPeriodPlot: React.FC<any> = ({ className, isHeatmapTotal = true, setHeatmapDownloadCSV }: IHeatMap) => {
  const { calendar_heatmap_busiest_period } = useSelector((state: any) => state.heatmap);
  const [layout, setLayout] = useState<any>();
  const [heatmapData, setHeatmapData] = useState<number[][]>([]);
  const [maxTotalValue, setMaxTotalValue] = useState<number>(0);

  const xAxis = useMemo(() => (['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']), []);

  const yAxis = useMemo(() => ([
    '11:30', '11:40', '11:50', '12:00', '12:10', '12:20', '12:30', '12:40', '12:50', '13:00', '13:10', '13:20', '13:30',
    '13:40', '13:50', '14:00', '14:10', '14:20', '14:30', '14:40', '14:50', '15:00', '15:10', '15:20', '15:30',
  ]), []);

  useEffect(() => {
    if (!calendar_heatmap_busiest_period || !calendar_heatmap_busiest_period.store_calendar_heatmap_busiest_period_graph) {
      setHeatmapData([]);
      return;
    }

    const heatmap = [
      // M, T, W, T, F
      [0, 0, 0, 0, 0], // 11:30
      [0, 0, 0, 0, 0], // 11:40
      [0, 0, 0, 0, 0], // 11:50
      [0, 0, 0, 0, 0], // 12:00
      [0, 0, 0, 0, 0], // 12:10
      [0, 0, 0, 0, 0], // 12:20
      [0, 0, 0, 0, 0], // 12:30
      [0, 0, 0, 0, 0], // 12:40
      [0, 0, 0, 0, 0], // 12:50
      [0, 0, 0, 0, 0], // 13:00
      [0, 0, 0, 0, 0], // 13:10
      [0, 0, 0, 0, 0], // 13:20
      [0, 0, 0, 0, 0], // 13:30
      [0, 0, 0, 0, 0], // 13:40
      [0, 0, 0, 0, 0], // 13:50
      [0, 0, 0, 0, 0], // 14:00
      [0, 0, 0, 0, 0], // 14:10
      [0, 0, 0, 0, 0], // 14:20
      [0, 0, 0, 0, 0], // 14:30
      [0, 0, 0, 0, 0], // 14:40
      [0, 0, 0, 0, 0], // 14:50
      [0, 0, 0, 0, 0], // 15:00
      [0, 0, 0, 0, 0], // 15:10
      [0, 0, 0, 0, 0], // 15:20
      [0, 0, 0, 0, 0], // 15:30
    ];

    const { store_calendar_heatmap_busiest_period_graph: { busiest_heatmap = [], max_total_value } } = calendar_heatmap_busiest_period;
    setMaxTotalValue(max_total_value);

    if (max_total_value > 0) {
      busiest_heatmap.forEach((items: any) => {
        items.forEach((item: any) => {
          const { weekday, hour, minute, total_value, average_value } = item;

          const value = (isHeatmapTotal ? total_value : average_value) ?? 0;

          const minuteHeatMapPosition = ((hour - 11) * 6) + Math.floor(minute / 10);

          if (value !== 0 && minuteHeatMapPosition >= 0 && minuteHeatMapPosition < heatmap.length) {
            heatmap[minuteHeatMapPosition][weekday] = value;
          }
        });
      });
    }

    setHeatmapData(heatmap);
  }, [calendar_heatmap_busiest_period, isHeatmapTotal]);

  useEffect(() => {
    if (setHeatmapDownloadCSV) {
      const csv = [] as any;
      csv.push(['', ...xAxis]);
      heatmapData.forEach((row: number[], i: number) => {
        csv.push([yAxis[i], ...row]);
      });
      setHeatmapDownloadCSV(csv);
    }
  }, [heatmapData, setHeatmapDownloadCSV, xAxis, yAxis]);

  useEffect(() => {
    const newLayout = {
      height: 450,
      margin: { t: 0, r: 10, b: 50, l: 60 },
      showlegend: false,
      xaxis: { showgrid: false },
      yaxis: { showgrid: false, autorange: 'reversed' },
      font: {
        size: 12,
      },
      annotations: maxTotalValue === 0 ? [
        {
          text: 'No matching data found',
          xref: 'paper',
          yref: 'paper',
          showarrow: false,
          font: { size: 18, family: 'Lato', color: '#3F404F' },
        },
      ] : [],
    };

    setLayout(newLayout);
  }, [heatmapData]);

  const config: Partial<PlotProps['config']> = {
    responsive: false,
    displaylogo: false,
    modeBarButtonsToRemove,
  };

  const colorScaleValues = [
    [0, '#EAEAEB'],
    [1, '#5361ff'],
  ];

  const noDataColor = [
    [0, '#fefefe'],
    [1, '#E0E0ED'],
  ];

  return (
    <Plot
      data={[
        {
          x: xAxis,
          y: yAxis,
          z: heatmapData,
          xgap: 5,
          ygap: 5,
          type: 'heatmap',
          colorscale: maxTotalValue > 0 ? colorScaleValues : noDataColor,
          showscale: false,
          hovertemplate: `${isHeatmapTotal ? 'Total footfall' : 'Avg. Queue Length'}: %{z}<extra></extra>`,
        },
      ]}
      layout={layout}
      config={config}
      className={className}
    />
  );
};

export default HeatMapCalendarBusiestPeriodPlot;
