import moment from 'moment';
import {
  convertOneHourPercentageToMinutesAndSeconds,
  getHoursMinutesAndSeconds,
  getSimpleMinutesAndSeconds,
} from 'src/utils/helpers';

function cameraPerformancePlotData({
  graphData,
  graphPerformance,
  categories: newCategories,
  labels,
  indexesToRemove,
}: any) {
  const filteredData = graphData
    ? graphData.filter((d: any) => d.name === 'cam_total_footfall_graph' || d.name === 'cam_average_time_graph')
    : [];
  filteredData.sort((a: any, b: any) => {
    return a.name === 'cam_total_footfall_graph' ? -1 : 1;
  });
  const mappedData = [] as any[];
  filteredData.forEach((d: any, i: number) => {
    const parsedData = {
      ...d,
      data: d?.data?.filter((_: any, index: number) => !indexesToRemove.includes(index)),
    };

    if (parsedData.data && parsedData.data.length > 0) {
      const label = labels.find((label: any) => label.id === parsedData.name);
      const axis = label?.id === 'cam_total_footfall_graph' ? 'xaxis' : 'yaxis';
      const footfallText =
        graphPerformance?.xaxis.visible && label?.id === 'cam_total_footfall_graph'
          ? '<b style="color: #333;">Queue Length:</b> <span style="color: #53D2C5;">%{y} </span>'
          : '';
      const avgtimeText =
        graphPerformance?.yaxis.visible && label?.id !== 'cam_total_footfall_graph'
          ? '<b style="color: #333;">Queue Length Avg. Time:</b> <span style="color: #53D2C5;">%{customdata}</span>'
          : '';

      mappedData.push({
        color: label?.color,
        customdata: parsedData.data.map((time: number) => {
          if (parsedData.name === 'cam_average_time_graph') {
            return getSimpleMinutesAndSeconds(time);
          }
          return time;
        }),
        // customdata: parsedData.data.map((time: number) => getSimpleMinutesAndSeconds(time)),
        id: parsedData.name,
        mode: parsedData.data.length === 1 ? 'markers' : 'lines',
        marker: {
          color: '#6a6a6a',
        },
        name: label?.name,
        type: 'scatter',
        visible: true,
        x: newCategories,
        y: parsedData.data.map((val: number) => {
          if (parsedData.name === 'cam_average_time_graph') {
            return val / 60;
          }
          return val.toFixed();
        }),
        yaxis: `y${i + 1}`,
        line: {
          color: label?.colorHex,
          shape: 'spline',
          smoothing: 0,
          width: graphPerformance && graphPerformance[axis].visible ? 2 : 0,
        },
        hoverinfo: 'y',
        hoverlabel: {
          bgcolor: '#fff',
          font: { size: 14, family: 'Lato' },
          bordercolor: '#ebeaea',
        },
        hovertemplate: `${footfallText}${avgtimeText}<extra></extra>`,
      });
    }
  });

  return mappedData;
}

function cameraPerformanceCSVDownload({ graph_data: camGraphData, categories }: any) {
  const performanceData = [] as any;
  performanceData.push(['Date/Time', 'Camera Total Footfall', 'Camera Avg. Time']);
  if (camGraphData && categories) {
    categories?.forEach((cat: number) => {
      performanceData.push([moment(cat * 1000).format('DD/MM/YY HH:mm')]);
    });
    performanceData?.forEach((cat: any, i: number) => {
      if (i === 0) {
        return;
      }
      camGraphData.forEach((item: any) => {
        if (item.name === 'cam_total_footfall_graph') {
          item?.data?.forEach((data: any, i: number) => {
            if (performanceData[i + 1]) {
              performanceData[i + 1][1] = data;
            }
          });
        }
        if (item.name === 'cam_average_time_graph') {
          item?.data?.forEach((data: any, i: number) => {
            if (performanceData[i + 1]) {
              performanceData[i + 1][2] = getHoursMinutesAndSeconds(data);
            }
          });
        }
      });
    });
  }
  return performanceData;
}

function cameraPerformancePlotLayout({ graphPerformance, mappedData, categories: newCategories, tickFormat }: any) {
  if (mappedData.length === 0) {
    return {
      xaxis: { visible: false },
      yaxis: { visible: false },
      annotations: [
        {
          text: 'No matching data found',
          xref: 'paper',
          yref: 'paper',
          showarrow: false,
          font: { size: 14, color: '#3F404F' },
        },
      ],
    };
  }

  const showXaxis = graphPerformance.xaxis.visible;
  const showYaxis = graphPerformance.yaxis.visible;
  const newPlotLayout = {
    title: '',
    showlegend: false,
    responsive: true,
    displayModeBar: false,
    margin: { t: 0 },
    hovermode: graphPerformance && (showXaxis || showYaxis) ? 'x unified' : 'closest',
    annotations: [
      {
        text: graphPerformance && !showXaxis && !showYaxis ? 'Please select the graph you want to display' : '',
        xref: 'paper',
        yref: 'paper',
        showarrow: false,
        font: { size: 14, color: '#3F404F' },
      },
    ],
    xaxis: {
      tickmode: newCategories.length ? 'auto' : undefined,
      hoverformat: tickFormat,
      tickformat: tickFormat,
      tickvals: [newCategories],
      ticktext: newCategories.map((c: any) => moment(c).format('DD MMM HH:mm')),
      domain: [0.01, 0.95],
      showticklabels: true,
      showgrid: true,
    },
    yaxis: {
      tickfont: { color: '#68B0FF' },
      position: 0,
      type: 'linear',
      visible: (graphPerformance && graphPerformance.xaxis.visible) || false,
    },
    yaxis2: {
      visible: (graphPerformance && graphPerformance.yaxis.visible) || false,
      tickfont: { color: '#EB5757' },
      overlaying: 'y',
      side: 'right',
      position: 0.97,
      showgrid: false,
      type: 'linear',
    },
  };
  return newPlotLayout;
}

export { cameraPerformancePlotData, cameraPerformanceCSVDownload, cameraPerformancePlotLayout };
