import { axiosJsonInstance } from 'src/store/api';
import { isEmptyOrUndefined } from 'src/utils/isUndefined';

export default {
  async getHeatmap(
    cameraId: any,
    startDate: any,
    endDate: any,
    areaType: any,
    thresholdFilter: any,
    genderFilter: any,
    ageFilter: any,
    region: any,
  ) {
    return axiosJsonInstance.get(`/e_search/heatmap/${cameraId}/`, {
      params: {
        start_date: startDate,
        end_date: endDate,
        area_type: areaType,
        threshold_second: thresholdFilter,
        gender: !isEmptyOrUndefined(genderFilter) ? genderFilter : '',
        age_range: !isEmptyOrUndefined(ageFilter) ? ageFilter : '',
        region,
      },
    });
  },

  async getCalendarHeatmap(storeId: any, startDate: any, endDate: any, ageFilter: any, genderFilter: any, areaId: any) {
    return axiosJsonInstance.get(`/e_search/calendar_heatmap/${storeId}/`, {
      params: {
        start_date: startDate,
        end_date: endDate,
        age_range: !isEmptyOrUndefined(ageFilter) ? ageFilter : '',
        gender: !isEmptyOrUndefined(genderFilter) ? genderFilter : '',
        area_id: areaId,
      },
    });
  },
  async getCalendarHeatmapCSV(storeId: any, startDate: any, endDate: any, areaId: any) {
    return axiosJsonInstance.get(`/e_search/calendar_heatmap/${storeId}/`, {
      params: {
        start_date: startDate,
        end_date: endDate,
        area_id: areaId,
      },
      headers: {
        Accept: 'text/csv',
      },
    });
  },
  async getDownloadCSV(storeId: any, type = 'calendar_heatmap', filename: any, startDate: any, endDate: any) {
    return axiosJsonInstance.get(`/e_search/${type}/${storeId}/`, {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
      headers: {
        Accept: 'text/csv',
      },
    });
  },
  async getCalendarHeatmapBusiestPeriod(storeId: any, startDate: any, endDate: any, areaId: any) {
    return axiosJsonInstance.get(`/e_search/calendar_heatmap_busiest_period/${storeId}/`, {
      params: {
        start_date: startDate,
        end_date: endDate,
        area_id: areaId,
      },
    });
  },
};
