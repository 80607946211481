/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';

import Store from 'src/pages/StoreDetail';
import Dashboard from 'src/pages/Dashboard';
import Cameras from 'src/pages/Cameras';
import { AdminDashboard, Stores, StoresUsers } from 'src/pages/SuperAdmin';
import DepartamentSettings from 'src/pages/DepartamentSettings';
import { PublicRoutes } from './PublicRoutes';
import { useSelector } from 'react-redux';
import Sky from 'src/pages/Sky';

interface Props {
  Component: React.FC<RouteComponentProps>;
  path: string;
  exact?: boolean;
  isSuper?: boolean;
}

const SuperAdminRoute = ({ Component, path, exact = false, ...rest }: Props) => {
  const isAuthed = !!localStorage.getItem('token');

  return (
    <Route
      exact={exact}
      path={path}
      render={(props: RouteComponentProps) => {
        return isAuthed ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: '/sky',
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

const PrivateRoute = (props: any) => {
  return localStorage.getItem('token') ? <Route {...props} /> : <Redirect to="/login" />;
};

const Routes = () => {
  const { is_superuser } = useSelector(({ auth }: any) => auth.user);

  return (
    <Switch>
      <Route exact path="/">
        <Redirect to={!localStorage.getItem('token') ? '/login' : '/sky'} />
      </Route>

      <PrivateRoute path="/dashboard" component={Dashboard} />
      <PrivateRoute path="/store/:id" component={Store} />
      <PrivateRoute path="/camera/:storeId/:departmentId/:cameraId/:areaId?" component={Cameras} />
      <PrivateRoute path="/area/:storeId/:departmentId/:cameraId/:areaId" component={Cameras} />
      <PrivateRoute path="/department-settings/:storeId/:departmentId" component={DepartamentSettings} />
      <PrivateRoute path="/sky" component={Sky} />

      {PublicRoutes.map((item, index) => (
        <Route path={item.path} component={item.component} key={index} />
      ))}

      {is_superuser && (
        <>
          <SuperAdminRoute path="/super-admin/admin-dashboard" Component={AdminDashboard} isSuper />
          <SuperAdminRoute path="/super-admin/stores/:brandId" Component={Stores} isSuper />
          <SuperAdminRoute path="/super-admin/users/:brandId" Component={StoresUsers} isSuper />
        </>
      )}

    </Switch>
  );
};

export default Routes;
