/* eslint-disable linebreak-style */
/* eslint-disable indent */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Plot from 'src/components/plotly';
import PlotProps from 'src/components/plotly/types';
import cx from 'classnames';
import moment from 'moment-timezone';
import { modeBarButtonsToRemove } from 'src/utils/constants';
import { useSelector } from 'react-redux';
import { getFormatDateTimeStamp, getTimestampOf } from 'src/utils/helpers';

type Params = {
  categories: string[];
  graphData: any[];
  performancePlotData: any;
  performancePlotLayout: any;
  graphsPrefix: 'cam' | 'store';
};

interface MappedData {
  x: string[];
  y: any;
  customdata: any;
  visible: boolean;
  id: string;
  yaxis: string;
  name: string | undefined;
  type: string;
  color: string | undefined;
  mode: string;
  line: { color: string | undefined; shape: string; smoothing: number; width: number };
  hoverinfo: string;
  hoverlabel: { bgcolor: string | undefined; font: { size: number; family: string }; bordercolor: string };
  hovertemplate: string;
}

const PerformancePlot = ({
  categories,
  graphData,
  performancePlotData,
  performancePlotLayout,
  graphsPrefix,
}: Params) => {
  const { interval, graphPerformance, graphCameraPerformance } = useSelector((state: any) => state.graphs);
  const { startDate, endDate } = useSelector((state: any) => state.dateTime);

  const startTimeTimestamp = Math.trunc(getTimestampOf(new Date(startDate)));
  const endTimeTimestamp = Math.trunc(getTimestampOf(new Date(endDate)));

  const [indexesToRemove, setIndexesToRemove] = useState<number[]>([]);
  const [plotData, setPlotData] = useState<any[]>([]);
  const [plotLayout, setPlotLayout] = useState<any>({});

  const labels = [
    {
      id: `${graphsPrefix}_total_footfall_graph`,
      name: 'Queue Length',
      color: 'bg-accent',
      colorHex: '#68B0FF',
    },
    {
      id: `${graphsPrefix}_average_time_graph`,
      name: 'Queue Length Avg. Time',
      color: 'bg-red',
      colorHex: '#EB5757',
    },
  ];

  const newCategories = useMemo(() => {
    if (indexesToRemove.length > 0) {
      setIndexesToRemove([]);
    }

    categories?.forEach((categorie, index) => {
      const date = moment.unix(Number(categorie));
      const userStartDate = moment.unix(startTimeTimestamp);
      const userEndDate = moment.unix(endTimeTimestamp);

      if (date.isBefore(userStartDate) || date.isAfter(userEndDate)) {
        setIndexesToRemove((prev) => [...prev, index]);
      }
    });

    return categories?.map((cat) => getFormatDateTimeStamp(cat, 'YYYY-MM-DD HH:mm'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories, startTimeTimestamp, endTimeTimestamp]);

  const tickFormat = useMemo(() => {
    if (!newCategories?.length) return undefined;

    if (interval === '1m') {
      return '%B';
    }

    if (interval === '1h' || interval === '600s') {
      return '%b %d %H:%M';
    }

    return '%b %d';
  }, [interval, newCategories]);

  useEffect(() => {
    const mappedData = performancePlotData({
      graphData,
      categories: newCategories,
      graphPerformance: graphsPrefix === 'store' ? graphPerformance : graphCameraPerformance,
      labels,
      indexesToRemove,
    });

    const layout = performancePlotLayout({
      graphPerformance: graphsPrefix === 'store' ? graphPerformance : graphCameraPerformance,
      mappedData,
      categories: newCategories,
      tickFormat,
    });

    setPlotData(mappedData);
    setPlotLayout(layout);
  }, [graphsPrefix, tickFormat, graphData, tickFormat, newCategories, indexesToRemove, graphPerformance, graphCameraPerformance]);

  const config: Partial<PlotProps['config']> = {
    responsive: false,
    displaylogo: false,
    modeBarButtonsToRemove,
  };

  const renderPlotData = useCallback((item: { id: string; name: string; color: string }) => {
    const axis = item?.id === `${graphsPrefix}_total_footfall_graph` ? 'xaxis' : 'yaxis';
    const graph = graphsPrefix === 'store' ? graphPerformance : graphCameraPerformance;
    if (graph && graph[axis].visible) {
      return (
        <span className="text-md" key={item.name}>
          <div className={cx('legend-marker', item.color)} />
          {item.name}
        </span>
      );
    }
    return '';
  }, [graphsPrefix, graphPerformance, graphCameraPerformance]);

  return (
    <div className="flex flex-col relative">
      <Plot className="mb-4" data={plotData} layout={plotLayout} config={config} />
      <div className="flex w-full justify-between py-6 px-20 absolute z-10 bottom-0">
        {plotData.map(renderPlotData)}
      </div>
    </div>
  );
};

export default PerformancePlot;
