import * as Action from './actions';

const initialState = {
  heatmap: {},
  loading: false,
  error: null,

  calendar_heatmap: {},

  calendar_heatmap_busiest_period: {},
  calendar_heatmap_busiest_period_loading: false,
  calendar_heatmap_busiest_period_error: null,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case Action.GET_HEATMAP: {
      return {
        ...state,
        loading: true,
      };
    }
    case Action.GET_HEATMAP_SUCCESS: {
      return {
        ...state,
        heatmap: action.payload,
        loading: false,
      };
    }
    case Action.GET_HEATMAP_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case Action.GET_CALENDAR_HEATMAP: {
      return {
        ...state,
        loading: true,
      };
    }
    case Action.GET_CALENDAR_HEATMAP_SUCCESS: {
      return {
        ...state,
        calendar_heatmap: action.payload,
        loading: false,
      };
    }
    case Action.GET_CALENDAR_HEATMAP_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case Action.GET_CALENDAR_HEATMAP_BUSIEST_PERIOD: {
      return {
        ...state,
        calendar_heatmap_busiest_period_loading: true,
      };
    }
    case Action.GET_CALENDAR_HEATMAP_BUSIEST_PERIOD_SUCCESS: {
      return {
        ...state,
        calendar_heatmap_busiest_period: action.payload,
        calendar_heatmap_busiest_period_loading: false,
      };
    }
    case Action.GET_CALENDAR_HEATMAP_BUSIEST_PERIOD_FAILURE: {
      return {
        ...state,
        calendar_heatmap_busiest_period_loading: false,
        calendar_heatmap_busiest_period_error: action.error,
      };
    }
    default:
      return state;
  }
};
