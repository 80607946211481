import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Layout from 'src/components/layout';
import {
  convertSecondsToHMS,
  getTimestampOf,
} from 'src/utils/helpers';
import * as ActionTypes from 'src/store/actions';
import { Row } from 'src/components/Row';
import { Card } from 'src/components/Card';
import { Col } from 'src/components/Col';
import { Table } from 'src/components/Table';
import { groupBy, orderBy } from 'lodash';
import { MdRefresh } from 'react-icons/md';
import { Tooltip } from 'src/components/Tooltip';

export default function Sky() {
  const dispatch = useDispatch();
  const { startDate, endDate, lastUpdate } = useSelector((state: any) => state.dateTime);
  const { genderFilter, ageFilter } = useSelector((state: any) => state.filter);
  const { dwellAnalysis, dwellAnalysisLoading } = useSelector((state: any) => state.areas);
  const { storeGraphs, storeGraphs_loading } = useSelector((state: any) => state.graphs);
  const { stores } = useSelector((state: any) => state.stores);
  const [dwellAnalysisData, setDwellAnalysisData] = useState([] as any);
  const startTimeTimestamp = Math.trunc(getTimestampOf(new Date(startDate)));
  const endTimeTimestamp = Math.trunc(getTimestampOf(new Date(endDate)));

  const fetchDwellAnalysisData = (seconds = '') => {
    dispatch(
      ActionTypes.getDwellAnalysis.request({
        departmentId: undefined,
        cameraId: undefined,
        areaType: 'DA',
        startDate: startTimeTimestamp,
        endDate: endTimeTimestamp,
        genderFilter: undefined,
        ageFilter: undefined,
        thresholdFilter: seconds,
      }),
    );
  };

  useEffect(() => {
    fetchDwellAnalysisData();
    if (stores.length === 0) {
      dispatch(ActionTypes.getAllStores.request({}));
    }
  }, [startTimeTimestamp, endTimeTimestamp, genderFilter, ageFilter]);

  const mapDwellAnalysisData = (dwellData: any[]) => {
    return dwellData?.map((dwell: any) => {
      const cameraName = dwell.camera.es_cam_id as string;
      const capitalizedCamName = cameraName.charAt(0).toUpperCase() + cameraName.slice(1);
      return {
        key: dwell.id,
        id: dwell.id,
        cameraName: `${capitalizedCamName}`,
        areas: `${capitalizedCamName} - ${dwell.name}`,
        noOfViews: dwell.footfall_metrics,
        avgQueueLength: dwell.avg_queue_length ?? 0,
        avgTime: convertSecondsToHMS(dwell.time_metrics?.average_time),
      };
    });
  };

  useEffect(() => {
    if (!dwellAnalysis.length) {
      setDwellAnalysisData([]);
      return;
    }

    const dwellData = dwellAnalysis.filter((data: any) => data.camera.store === 3 || data.camera.store === 4);
    const grouped = groupBy(dwellData, 'camera.store');

    // Hardcoded for now for testing purposes
    const mainRestaurantData = mapDwellAnalysisData(grouped[3]);
    const marketData = mapDwellAnalysisData(grouped[4]);

    const mappedData = {
      'Main Restaurant': orderBy(mainRestaurantData, ['noOfViews', 'avgQueueLength', 'avgTime'], ['desc', 'desc', 'desc']),
      Market: orderBy(marketData, ['noOfViews', 'avgQueueLength', 'avgTime'], ['desc', 'desc', 'desc']),
    };

    setDwellAnalysisData(mappedData);
  }, [storeGraphs, storeGraphs_loading, dwellAnalysis, dwellAnalysisLoading, startTimeTimestamp, endTimeTimestamp]);

  const titleLastUpdate = moment(lastUpdate).format('DD/MM/YYYY HH:mm');
  const updateAction = () => {
    const now = moment().subtract(2, 'months').toISOString();
    const weekBeforeLastUpdate = moment(now).subtract(3, 'days').startOf('day').toDate();
    dispatch(ActionTypes.updateLastUpdate(now));
    dispatch(ActionTypes.updateStartDate(weekBeforeLastUpdate));
    dispatch(ActionTypes.updateEndDate(now));
  };
  const lastUpdateAction = (
    <button className="group flex gap-2 cursor-pointer disabled:cursor-not-allowed" type="button" onClick={updateAction} disabled={dwellAnalysisLoading}>
      <Tooltip tooltipText="Refresh now" tooltipPlacement="top" width={100}>
        <span className=" inline-flex gap-3 text-sm items-center group-disabled:cursor-not-allowed hover:bg-primary/50 transition-all rounded p-2 group-disabled:hover:bg-transparent">
          <span className="group-disabled:text-neutral-400">
            Update
          </span>
          <MdRefresh size={25} className="text-primary-dark/80 group-disabled:text-neutral-400 group-disabled:group-hover:rotate-0 group-hover:rotate-180 transition-all" />
        </span>
      </Tooltip>
    </button>
  );
  return (
    <Layout title="Sky">
      <Row>
        <Col size="12">
          <Card title="Last updates" subTitle={`Last updated at ${titleLastUpdate}`} iconsGroup={lastUpdateAction} />
        </Col>
      </Row>
      <Row>
        <Col size="12">
          {dwellAnalysisLoading && (
            <div className="animate-pulse">
              <div role="status" className="w-full bg-white rounded-lg flex flex-col shadow gap-2">
                <div className="h-6 w-full bg-gray-200 rounded-lg br-5" />
                <div className="h-20 w-full bg-gray-200 rounded-lg br-5" />
              </div>
            </div>
          )}
          {!dwellAnalysisLoading && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
              {Object.keys(dwellAnalysisData).map((key) => (
                <>
                  {dwellAnalysisData[key].length === 0 && (
                    <div className="text-center text-neutral-600">
                      <span>No data available</span>
                    </div>
                  )}
                  {dwellAnalysisData[key].length > 0 && (
                    <Card title={key}>
                      <Table
                        cols={[
                          { title: 'Areas', field: 'areas', order: true, alignment: 'start' },
                          { title: 'Total Footfall', field: 'noOfViews', order: false, alignment: 'center' },
                          { title: 'Queue Length', field: 'avgQueueLength', order: false, alignment: 'center' },
                          { title: 'Avg. time', field: 'avgTime', order: false, alignment: 'end' },
                        ]}
                        rows={dwellAnalysisData[key]}
                      />
                    </Card>
                  )}
                </>
              ))}
            </div>
          )}
        </Col>
      </Row>
    </Layout>
  );
}
