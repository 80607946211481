/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from 'react';
import { Card } from 'src/components/Card';
import { Icon } from 'src/components/Icon';
import Logo from 'src/assets/images/TrackIn_Logo.svg';
import { useDispatch, useSelector } from 'react-redux';
import * as ActionTypes from 'src/store/actions';
import cx from 'classnames';
import { useLocation, useParams } from 'react-router-dom';
import { history } from 'src/utils/history';
import { groupBy, orderBy, uniqBy } from 'lodash';
import styles from './styles.module.css';

type IParamsProps = {
  id?: string;
  storeId?: string;
  cameraId?: string;
  areaId?: string
};

const SideMenu: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { cameraId, storeId, areaId } = useParams<IParamsProps>();
  const { areas } = useSelector((state: any) => state.areas);
  const { is_superuser } = useSelector(({ auth }: any) => auth.user);
  const [localMenuData, setLocalMenuData] = useState<any[]>();

  useEffect(() => {
    dispatch(
      ActionTypes.getAreas.request({
        areaType: 'DA',
      }),
    );
  }, [dispatch]);

  const handleClickMenu = (storeId: number, department: number, cameraId: number, areaId: number) => {
    // history.push(`/camera/${storeId}/${department}/${cameraId}/${areaId}`);
    history.push(`/area/${storeId}/${department}/${cameraId}/${areaId}`);
  };

  useEffect(() => {
    const dwellData = areas.filter((data: any) => data.camera.store === 3 || data.camera.store === 4);
    const grouped = groupBy(dwellData, 'camera.store');

    // Store 3 = Main Restaurant
    // Store 4 = Market
    // @ts-ignore
    const mainRestaurantAreas = grouped[3];
    // @ts-ignore
    const marketAreas = grouped[4];

    const menu = [
      {
        id: 3,
        name: 'Main Restaurant',
        active: (location.pathname.indexOf('area') > 0 && Number(storeId) === 3),
        areas: orderBy(mainRestaurantAreas, 'name', 'asc').map((area) => ({
          id: area.id,
          name: area.name,
          storeId: area.camera.store,
          cameraId: area.camera.id,
          department: area.camera.department,
          active: location.pathname.indexOf('area') > 0 && Number(areaId) === area.id,
        })),
      }, {
        id: 4,
        name: 'Market',
        active: (location.pathname.indexOf('area') > 0 && Number(storeId) === 4),
        areas: orderBy(marketAreas, 'name', 'asc').map((area) => ({
          id: area.id,
          name: area.name,
          cameraId: area.camera.id,
          storeId: area.camera.store,
          department: area.camera.department,
          active: (location.pathname.indexOf('area') > 0 && Number(areaId) === area.id),
        })),
      },
    ];

    setLocalMenuData(menu);
  }, [areas, storeId, cameraId, location.pathname]);

  return (
    <Card className="md:min-h-[100vh] h-full sticky top-0 left-0 overflow-y-auto">
      <div
        className="w-full mb-5 pt-3 cursor-pointer sticky top-0 left-0 bg-white py-2"
        onClick={() => history.push(is_superuser ? '/super-admin/admin-dashboard' : '/')}
      >
        <img src={Logo} width="74" alt="TrackIn" className="mx-auto" />
      </div>
      <div
        className={cx('p-2 cursor-pointer', {
          'pl-0 border-l-4 border-primary bg-gray-100': location.pathname === '/sky',
        })}
        onClick={() => history.push('/sky')}
      >
        <Icon icon="dashboard" className="mt-1 mx-2" />
        Sky
      </div>
      {localMenuData &&
        localMenuData.map((store: any) => (
          <div
            key={store.id}
            className={`${styles.menuItem} ${cx('p-2', {
              'pl-0 bg-gray-100': store.active,
            })}`}
          >
            <div
              title={store.name}
              className={`${cx('p-2 flex flex-row pb-0 cursor-default justify-between', {
                'border-primary': store.active,
              })}`}
            >
              <p className="truncate">
                <Icon icon="store" className="mt-1 mr-2" />
                {store.name}
              </p>
              <div>
                <Icon icon="arrow-down" className="mt-2 mr-2 float-right" />
              </div>
            </div>
            <ul
              className={cx(
                `pointer ${store.active ? styles.activeSubmenu : ''}
                }`,
              )}
            >
              {store.areas.map((area: any) => (
                <div
                  title={area.name}
                  key={`${store.id}-${area.id}`}
                  className={`flex h-10 text-gray-600 hover:bg-primary-dark/30 ${area.active ? 'border-l-4 border-primary' : ''}`}
                  onClick={() => handleClickMenu(area.storeId, area.department, area.cameraId, area.id)}
                >
                  <div className="ml-2 self-center">
                    <Icon icon="cameraVideo" />
                  </div>
                  <div className="truncate">
                    {area.name}
                  </div>
                </div>
              ))}
            </ul>
          </div>
        ))}
    </Card>
  );
};
export default SideMenu;
