/* eslint-disable indent */
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Col } from 'src/components/Col';
import { RangeDatePicker } from 'src/components/datepicker';
import { Filters, FiltersProps } from 'src/components/Filters';
import Layout from 'src/components/layout';
import ExtraHeaders from 'src/components/layout/header/extraHeaders';
import { Row } from 'src/components/Row';
import { AGE_FILTER, GENDER_FILTER } from 'src/constants/filters';
import { IUseParamsCameras } from 'src/interfaces/common.interface';
import { IHeaderData } from 'src/interfaces/store.interface';
import * as ActionTypes from 'src/store/actions';
import { getTimestampOf, today } from 'src/utils/helpers';

import PerformanceGraph from 'src/components/PerformanceGraph/PerformanceGraph';
import HeatMapCalendarPlot from 'src/pages/StoreDetail/heatMapCalendarPlot';
import { Tooltip } from 'src/components/Tooltip';
import { DownloadCSV } from 'src/components/DownloadCSV';
import { Icon } from 'src/components/Icon';
import { Card } from 'src/components/Card';
import {
  cameraPerformanceCSVDownload,
  cameraPerformancePlotData,
  cameraPerformancePlotLayout,
} from './utils/performance.util';
import CameraIndicators from './components/CameraIndicators';
import HeatMapCalendarBusiestPeriodPlot from '../StoreDetail/heatMapCalendarBusiestPeriodPlot';
import { useModal } from 'src/hooks/useModal';
import { Modal } from 'src/components/Modal';

const FILTERS: FiltersProps['filters'] = [GENDER_FILTER, AGE_FILTER];

const Cameras: React.FC = () => {
  const { storeId, departmentId, cameraId, areaId } = useParams<IUseParamsCameras>();
  const dispatch = useDispatch();

  const { startDate, endDate } = useSelector((state: any) => state.dateTime);
  const { genderFilter, ageFilter } = useSelector((state: any) => state.filter);
  const { stores } = useSelector((state: any) => state.stores);
  const [heatmapDownloadCSV, setHeatmapDownloadCSV] = useState([]);
  const [isOpen, onClose, onOpen] = useModal();
  const { interval, allCameraGraphsLoading } = useSelector((state: any) => state.graphs);
  const { areaDetails, areaDetailsLoading } = useSelector((state: any) => state.areas);
  const { loading: calendarHeatmapLoading } = useSelector((state: any) => state.heatmap);
  const [headerData, setHeaderData] = useState<IHeaderData>({
    address: '',
    footFall: '',
    location: '',
    timeSpent: '',
    avgTime: '',
  });

  const startTimeTimestamp = Math.trunc(getTimestampOf(new Date(startDate)));
  const endTimeTimestamp = Math.trunc(getTimestampOf(new Date(endDate)));
  const intervalLabel = `${moment(startDate).format('DD/MM/YYYY HH:mm')} - ${moment(endDate).format(
    'DD/MM/YYYY HH:mm',
  )}`;

  useEffect(() => {
    if (areaId) {
      dispatch(
        ActionTypes.getAreaDetails.request({
          areaId: areaId || undefined,
          areaType: 'DA',
          startDate: startTimeTimestamp,
          endDate: endTimeTimestamp,
          genderFilter: genderFilter || undefined,
          ageFilter: ageFilter || undefined,
        }),
      );
    }
  }, [dispatch, areaId, startTimeTimestamp, endTimeTimestamp, genderFilter, ageFilter]);

  useEffect(() => {
    if (stores.length === 0) {
      dispatch(ActionTypes.getAllStores.request({}));
    }
  }, [dispatch, storeId, stores]);

  useEffect(() => {
    if (!areaId) {
      return;
    }
    dispatch(
      ActionTypes.getWeekCameraGraphs.request({
        // bug
        storeId,
        departmentId,
        cameraId,
        startDate: startTimeTimestamp, // Math.trunc(getTimestampOf(new Date(lastWeekTime))),
        endDate: today / 1000,
        interval: '1d',
        areaId,
      }),
    );
    dispatch(
      ActionTypes.getAllCameraGraphs.request({
        storeId,
        departmentId,
        cameraId,
        startDate: startTimeTimestamp,
        endDate: endTimeTimestamp,
        interval,
        genderFilter: genderFilter || undefined,
        ageFilter: ageFilter || undefined,
        areaId,
      }),
    );
  }, [dispatch, storeId, departmentId, cameraId, startTimeTimestamp, endTimeTimestamp, genderFilter, ageFilter, interval, areaId]);

  useEffect(() => {
    dispatch(
      ActionTypes.getCalendarHeatmap.request({
        storeId,
        startDate: startTimeTimestamp,
        endDate: endTimeTimestamp,
        genderFilter: genderFilter || undefined,
        ageFilter: ageFilter || undefined,
        areaId,
      }),
    );
  }, [dispatch, cameraId, startTimeTimestamp, endTimeTimestamp, genderFilter, ageFilter]);

  useEffect(() => {
    dispatch(
      ActionTypes.getCalendarHeatmapBusiestPeriod.request({
        storeId,
        startDate: startTimeTimestamp,
        endDate: endTimeTimestamp,
        genderFilter: genderFilter || undefined,
        ageFilter: ageFilter || undefined,
        areaId,
      }),
    );
  }, [dispatch, cameraId, startTimeTimestamp, endTimeTimestamp, genderFilter, ageFilter]);

  useEffect(() => {
    const areaName = areaDetails.length > 0 ? areaDetails[0].name : '';
    const storeStringId = storeId ?? '0';
    const store = stores.find((store: any) => store.id === Number(storeStringId));
    const storeName = store.id === 3 ? 'Main Restaurant' : 'Market';
    setHeaderData({
      location: areaName ?? '',
      address: storeName ?? '',
      footFall: '',
      avgTime: '',
    });
  }, [cameraId, areaId, areaDetails]);

  const SectionFilters = (
    <Row>
      <Col className="flex flex-row gap-5">
        <RangeDatePicker needTime />

        {/* <Filters
          title="Filters"
          filters={FILTERS}
          onApplyFilters={({ age, gender }) => {
            const ageFilter = age ? age.join(',') : undefined;
            const genderFilter = gender ? gender.join(',') : undefined;
            dispatch(ActionTypes.updateAge(ageFilter));
            dispatch(ActionTypes.updateGender(genderFilter));
          }}
          onClear={() => {
            dispatch(ActionTypes.updateAge(''));
            dispatch(ActionTypes.updateGender(''));
          }}
        /> */}
      </Col>
    </Row>
  );

  return (
    <Layout
      title={headerData.location}
      subtitle={headerData.address}
      loadingHeader={areaDetailsLoading}
    >
      <div className="flex flex-col gap-2">
        {SectionFilters}
        <CameraIndicators />
        <PerformanceGraph
          intervalLabel={intervalLabel}
          performanceDataCSV={cameraPerformanceCSVDownload}
          performancePlotData={cameraPerformancePlotData}
          performancePlotLayout={cameraPerformancePlotLayout}
          type="camera"
          loading={allCameraGraphsLoading}
        />
      </div>

      <Row className="mt-5">
        <Col size="12">
          <Card
            className="w-full"
            title="Average Queue Length"
            iconsGroup={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <div className="flex gap-5">
                <Tooltip tooltipText="Busiest Period" tooltipPlacement="top">
                  <Icon className="text-xl" tag="button" onClick={onOpen} icon="fullScreen" />
                </Tooltip>
                <Tooltip tooltipText="Download" tooltipPlacement="top">
                  {heatmapDownloadCSV && (
                    <DownloadCSV filename={`Average Queue Length - ${intervalLabel}`} data={heatmapDownloadCSV}>
                      <Icon icon="download" className="text-xl" />
                    </DownloadCSV>
                  )}
                </Tooltip>
              </div>
            }
          >
            {calendarHeatmapLoading ? (
              <div className="animate-pulse bg-gray-50 rounded-lg shadow">
                <div role="status" className="bg-white p-4 rounded-lg shadow flex flex-col gap-2">
                  <div className="h-[300px] w-full bg-gray-200 rounded-lg br-5 mb-2.5" />
                </div>
              </div>
            ) : (
              <HeatMapCalendarPlot
                isHeatmapTotal={false}
                setHeatmapDownloadCSV={setHeatmapDownloadCSV}
                className="custom-heatmap"
              />
            )}
          </Card>
          {/* <Card
            className="w-full"
            title="Busiest Period"
          >
            {calendar_heatmap_busiest_period_loading ? (
              <div className="animate-pulse bg-gray-50 rounded-lg shadow">
                <div role="status" className="bg-white p-4 rounded-lg shadow flex flex-col gap-2">
                  <div className="h-[300px] w-full bg-gray-200 rounded-lg br-5 mb-2.5" />
                </div>
              </div>
            ) : (
              <HeatMapCalendarBusiestPeriodPlot
                isHeatmapTotal={false}
                setHeatmapDownloadCSV={setHeatmapDownloadCSV}
                className="custom-heatmap"
              />
            )}
          </Card> */}
        </Col>
      </Row>
      <Modal className="p-6" size="xl" isOpen={isOpen} onClose={onClose} title="Busiest Period" subTitle="Average Queue Length between 11:30 and 15:30 on weekdays">
        <HeatMapCalendarBusiestPeriodPlot
          isHeatmapTotal={false}
          setHeatmapDownloadCSV={setHeatmapDownloadCSV}
          className="custom-heatmap"
        />
      </Modal>
    </Layout>
  );
};

export default Cameras;
