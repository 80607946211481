export const UPDATE_START_DATE = 'UPDATE_START_DATE';
export const UPDATE_END_DATE = 'UPDATE_END_DATE';
export const UPDATE_LAST_UPDATE = 'UPDATE_LAST_UPDATE';

export const updateStartDate = (date: any) => {
  return {
    type: UPDATE_START_DATE,
    payload: date,
  };
};

export const updateEndDate = (date: any) => {
  return {
    type: UPDATE_END_DATE,
    payload: date,
  };
};

export const updateLastUpdate = (date: any) => {
  return {
    type: UPDATE_LAST_UPDATE,
    payload: date,
  };
};
