import React from 'react';
import { Col } from '../Col';
import { Row } from '../Row';
import Header from './header';
import SideMenu from './side-menu';

interface Params {
  title: string;
  subtitle?: string;
  children: React.ReactNode;
  extraHeader?: React.ReactNode;
  loading?: boolean;
  loadingHeader?: boolean;
}

const Layout = ({ title, subtitle, children, extraHeader, loading, loadingHeader }: Params) => (
  <>
    {loading && (
      <div className="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50">
        <span className="top-1/2 my-0 mx-auto block relative w-0 h-0">
          <i className="fas fa-circle-notch fa-spin fa-5x text-primary" />
        </span>
      </div>
    )}
    <div className="grid grid-cols-12 gap-y-10 md:gap-2 bg-gray-50 p-5 pr-2 overflow-auto">
      <div className="col-span-12 md:col-span-2">
        <SideMenu />
      </div>
      <div className="col-span-12 md:col-span-10">
        <Row>
          <Col>
            <Header title={title} subtitle={subtitle} loadingHeader={loadingHeader}>
              {extraHeader}
            </Header>
          </Col>
        </Row>

        <div className="py-4">{children}</div>
      </div>
    </div>
  </>
);

export default Layout;
