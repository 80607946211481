import { call, put, takeLatest, fork, all } from 'redux-saga/effects';
import { downloadCSV } from 'src/utils/helpers';
import { notifyError } from 'src/utils/notification';
import * as ActionTypes from './actions';
import Api from './api';

function* getHeatmap({ payload }: any) {
  try {
    const { data } = yield call(
      Api.getHeatmap,
      payload.cameraId,
      payload.startDate,
      payload.endDate,
      payload.areaType,
      payload.thresholdFilter,
      payload.genderFilter,
      payload.ageFilter,
      payload.region,
    );
    yield put(ActionTypes.getHeatmap.success(data));
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.getHeatmap.failure(e));
  }
}

function* getHeatmapSaga() {
  yield takeLatest(ActionTypes.GET_HEATMAP, getHeatmap);
}

function* getCalendarHeatmap({ payload }: any) {
  try {
    const { data } = yield call(
      Api.getCalendarHeatmap,
      payload.storeId,
      payload.startDate,
      payload.endDate,
      payload.ageFilter,
      payload.genderFilter,
      payload.areaId,
    );
    yield put(ActionTypes.getCalendarHeatmap.success(data));
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.getCalendarHeatmap.failure(e));
  }
}

function* getCalendarHeatmapCSVSaga() {
  yield takeLatest(ActionTypes.GET_CALENDAR_HEATMAP, getCalendarHeatmap);
}

function* getCalendarHeatmapCSV({ payload }: any) {
  try {
    const { data } = yield call(
      Api.getCalendarHeatmapCSV,
      payload.storeId,
      payload.startDate,
      payload.endDate,
      payload.areaId,
    );
    downloadCSV(data, 'performance_graph');
    yield put(ActionTypes.getCalendarHeatmapCSV.success(data));
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.getCalendarHeatmapCSV.failure(e));
  }
}

function* getCalendarHeatmapSaga() {
  yield takeLatest(ActionTypes.GET_CALENDAR_HEATMAP_CSV, getCalendarHeatmapCSV);
}

function* getDownloadCSV({ payload }: any) {
  try {
    const { data } = yield call(
      Api.getDownloadCSV,
      payload.storeId,
      payload.type,
      payload.filename,
      payload.startDate,
      payload.endDate,
    );
    downloadCSV(data, payload.filename);
    yield put(ActionTypes.getDownloadCSV.success(data));
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.getDownloadCSV.failure(e));
  }
}

function* getDownloadCSVSaga() {
  yield takeLatest(ActionTypes.GET_DOWNLOAD_CSV, getDownloadCSV);
}

function* getCalendarHeatmapBusiestPeriod({ payload }: any) {
  try {
    const { data } = yield call(
      Api.getCalendarHeatmapBusiestPeriod,
      payload.storeId,
      payload.startDate,
      payload.endDate,
      payload.areaId,
    );
    yield put(ActionTypes.getCalendarHeatmapBusiestPeriod.success(data));
  } catch (e) {
    notifyError(e as string);
    yield put(ActionTypes.getCalendarHeatmapBusiestPeriod.failure(e));
  }
}

function* getCalendarHeatmapBusiestPeriodSaga() {
  yield takeLatest(ActionTypes.GET_CALENDAR_HEATMAP_BUSIEST_PERIOD, getCalendarHeatmapBusiestPeriod);
}

export default function* rootSaga() {
  yield all([
    fork(getHeatmapSaga),
    fork(getCalendarHeatmapSaga),
    fork(getCalendarHeatmapCSVSaga),
    fork(getDownloadCSVSaga),
    fork(getCalendarHeatmapBusiestPeriodSaga),
  ]);
}
