import PlotProps from 'src/components/plotly/types';
import Plot from 'src/components/plotly';
import { IHeatMap } from 'src/interfaces/graph.interface';
import { modeBarButtonsToRemove } from 'src/utils/constants';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

const HeatMapCalendarPlot: React.FC<any> = ({ className, isHeatmapTotal = true, setHeatmapDownloadCSV }: IHeatMap) => {
  const { calendar_heatmap } = useSelector((state: any) => state.heatmap);
  const [layout, setLayout] = useState<any>();
  const [heatmapData, setHeatmapData] = useState<number[][]>([]);
  const [maxTotalValue, setMaxTotalValue] = useState<number>(0);

  const xAxis = useMemo(() => (['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']), []);

  const yAxis = useMemo(() => ([
    '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00',
  ]), []);

  useEffect(() => {
    if (!calendar_heatmap || !calendar_heatmap.store_calendar_heatmap_graph) {
      setHeatmapData([]);
      return;
    }

    const heatmap = [
      // M, T, W, T, F, S, S
      [0, 0, 0, 0, 0, 0, 0], // 09:00
      [0, 0, 0, 0, 0, 0, 0], // 10:00
      [0, 0, 0, 0, 0, 0, 0], // 11:00
      [0, 0, 0, 0, 0, 0, 0], // 12:00
      [0, 0, 0, 0, 0, 0, 0], // 13:00
      [0, 0, 0, 0, 0, 0, 0], // 14:00
      [0, 0, 0, 0, 0, 0, 0], // 15:00
      [0, 0, 0, 0, 0, 0, 0], // 16:00
      [0, 0, 0, 0, 0, 0, 0], // 17:00
      [0, 0, 0, 0, 0, 0, 0], // 18:00
      [0, 0, 0, 0, 0, 0, 0], // 19:00
      [0, 0, 0, 0, 0, 0, 0], // 20:00
      [0, 0, 0, 0, 0, 0, 0], // 21:00;
    ];

    const { store_calendar_heatmap_graph: { weekday_heatmap = [], max_total_value } } = calendar_heatmap;
    setMaxTotalValue(max_total_value);

    if (max_total_value > 0) {
      weekday_heatmap.forEach((items: any) => {
        items.forEach((item: any) => {
          const { weekday, hour, total_value, average_value } = item;

          const value = (isHeatmapTotal ? total_value : average_value) ?? 0;

          if (value !== 0 && hour >= 9 && hour <= 21) {
            // 9:00 is the first position of the heatmap (index 0)
            const hourHeatMapPosition = hour - 9;
            heatmap[hourHeatMapPosition][weekday] = value;
          }
        });
      });
    }

    setHeatmapData(heatmap);
  }, [calendar_heatmap, isHeatmapTotal]);

  useEffect(() => {
    if (setHeatmapDownloadCSV) {
      const csv = [] as any;
      csv.push(['', ...xAxis]);
      heatmapData.forEach((row: number[], i: number) => {
        csv.push([yAxis[i], ...row]);
      });
      setHeatmapDownloadCSV(csv);
    }
  }, [heatmapData, setHeatmapDownloadCSV, xAxis, yAxis]);

  useEffect(() => {
    const newLayout = {
      height: 345,
      margin: { t: 0, r: 10, b: 50, l: 60 },
      showlegend: false,
      xaxis: { showgrid: false },
      yaxis: { showgrid: false, autorange: 'reversed' },
      font: {
        size: 12,
      },
      annotations: maxTotalValue === 0 ? [
        {
          text: 'No matching data found',
          xref: 'paper',
          yref: 'paper',
          showarrow: false,
          font: { size: 18, family: 'Lato', color: '#3F404F' },
        },
      ] : [],
    };

    setLayout(newLayout);
  }, [heatmapData]);

  const config: Partial<PlotProps['config']> = {
    responsive: false,
    displaylogo: false,
    modeBarButtonsToRemove,
  };

  const colorScaleValues = [
    [0, isHeatmapTotal ? '#EAEAEB' : '#E0E0ED'],
    [1, isHeatmapTotal ? '#53D2C5' : '#5361ff'],
  ];

  const noDataColor = [
    [0, '#fefefe'],
    [1, '#E0E0ED'],
  ];

  return (
    <Plot
      data={[
        {
          x: xAxis,
          y: yAxis,
          z: heatmapData,
          xgap: 5,
          ygap: 5,
          type: 'heatmap',
          colorscale: maxTotalValue > 0 ? colorScaleValues : noDataColor,
          showscale: false,
          hovertemplate: `${isHeatmapTotal ? 'Total footfall' : 'Avg. Queue Length'}: %{z}<extra></extra>`,
        },
      ]}
      layout={layout}
      config={config}
      className={className}
    />
  );
};

export default HeatMapCalendarPlot;
