import { useEffect, useRef, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { Typography } from 'src/components/Typography';
import { Icon } from 'src/components/Icon';
import { useDispatch, useSelector } from 'react-redux';
import * as ActionTypes from 'src/store/actions';
import { BiCog, BiUserCircle } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { MdPersonAddAlt } from 'react-icons/md';

interface User {
  first_name: string;
}

interface Auth {
  auth: {
    user: User;
  };
}

interface PropsAuth {
  auth: {
    user: {
      is_superuser: boolean;
      is_brand_admin: boolean;
    };
  };
}

interface RoomsProps {
  stores: {
    store: {
      rooms: Array<{
        id: number;
        name: string;
        department: number;
        store: number;
      }>;
    };
  };
}

const NavMenu = () => {
  const { user } = useSelector((state: Auth) => state.auth);
  const [userData, setUserData] = useState<User>();
  const dispatch = useDispatch();
  const wrapperRef = useRef<any>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const is_superuser = useSelector((state: PropsAuth) => state.auth.user.is_superuser);
  const is_brand_admin = useSelector((state: PropsAuth) => state.auth.user.is_brand_admin);
  const rooms = useSelector((state: RoomsProps) => state.stores.store.rooms);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (isMenuOpen && wrapperRef.current && !wrapperRef.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  useEffect(() => {
    dispatch(ActionTypes.getUser.request({}));
  }, [dispatch]);

  useEffect(() => {
    setUserData(user);
  }, [user]);

  const handleLogoffClick = () => {
    dispatch(ActionTypes.postLogout.request({}));
  };

  return (
    <nav className="flex items-center justify-between w-full max-w-[174px]" ref={wrapperRef}>
      <div className="relative z-50 dropdown">
        <button
          className="flex items-center w-full max-w-[134px] py-1 px-2.5 "
          type="button"
          onClick={() => setIsMenuOpen((oldState) => !oldState)}
        >
          <BiUserCircle />
          <Typography variant="body-small" color="neutral-600" className="mx-1 truncate">
            {userData && userData.first_name}
          </Typography>
          {is_superuser && <Icon icon="arrowDown" className="w-3 h-3" />}
        </button>

        {isMenuOpen && (is_superuser || is_brand_admin) && (
          <div className="dropdown-menu transition-all duration-300 transform origin-top-right -translate-y-2 scale-95 bg-white">
            <div
              className="absolute left-0 w-48 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none"
              aria-labelledby="headlessui-menu-button-1"
              id="headlessui-menu-items-117"
              role="menu"
            >
              <div className="px-4 py-3">
                {rooms?.length && (is_brand_admin || is_superuser) && (
                  rooms.map((room) => (
                    <div key={room.id}>
                      <Link to={`/department-settings/${room.store}/${room.id}`} className="flex items-center">
                        <BiCog />
                        <span className="text-sm ml-1">Edit Department</span>
                      </Link>
                      <hr className="pb-2 mt-2" />
                    </div>
                  )))}
                {is_superuser && (
                  <Link to="/super-admin/admin-dashboard" className="flex items-center">
                    <MdPersonAddAlt />
                    <span className="text-sm ml-1">Super Admin View</span>
                  </Link>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <button title="Logout" className="flex items-center justify-center w-8 h-8" type="button" onClick={handleLogoffClick}>
        <Icon icon="logout" className="w-3 h-3" />
      </button>
    </nav>
  );
};

export default NavMenu;
