/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect, useMemo, useState } from 'react';
import { Card } from 'src/components/Card';
import { Col } from 'src/components/Col';
import { DownloadCSV } from 'src/components/DownloadCSV';
import { Icon } from 'src/components/Icon';
import { Row } from 'src/components/Row';
import { Tooltip } from 'src/components/Tooltip';
import {
  differenceBetweenTwoDates,
  getTimestampOf,
} from 'src/utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import * as ActionTypes from 'src/store/actions';
import PerformancePlot from './PerformancePlot';

type Props = {
  intervalLabel: string;
  performancePlotData: any;
  performancePlotLayout: any;
  performanceDataCSV: any;
  type: 'camera' | 'store';
  loading: boolean;
};

const PerformanceGraph: React.FC<Props> = ({
  intervalLabel,
  performancePlotData,
  performancePlotLayout,
  performanceDataCSV,
  type,
  loading,
}) => {
  const dispatch = useDispatch();
  const {
    interval,
    storeGraphs: { graph_data: storeGraphData, categories: storeCategories },
    allCameraGraphs: { graph_data: camGraphData, categories: camCategories },
  } = useSelector((state: any) => state.graphs);

  const { startDate, endDate } = useSelector((state: any) => state.dateTime);

  const [dateDiffInDays, setDateDiffInDays] = useState(0);
  const [dateDiffInMonths, setDateDiffInMonths] = useState(0);

  useEffect(() => {
    // Rules:
    // If 1 day or less, automatically changes to hourly
    // If 1 month or less, automatically changes to daily
    // If 6 months or less, automatically changes to weekly
    // If 6 months or more, automatically changes to monthly

    // If the date difference is less than 1 day, change graphs to hourly
    // if (dateDiffInDays <= 1 && interval !== '1h') {
    //   dispatch(ActionTypes.updateInterval.request('1h'));
    // } else if (dateDiffInDays <= 14 && interval !== '1d') {
    //   dispatch(ActionTypes.updateInterval.request('1d'));
    // } else if (dateDiffInDays >= 31 && dateDiffInMonths <= 6 && interval !== '1w') {
    //   dispatch(ActionTypes.updateInterval.request('1w'));
    // } else if (dateDiffInMonths >= 6 && interval !== '1m') {
    //   dispatch(ActionTypes.updateInterval.request('1m'));
    // } else if (dateDiffInDays >= 10 && interval === '1h') {
    //   dispatch(ActionTypes.updateInterval.request('1d'));
    // } else if (dateDiffInDays >= 31 && interval === '1d') {
    //   dispatch(ActionTypes.updateInterval.request('1w'));
    // } else if (dateDiffInMonths >= 6 && interval === '1w') {
    //   dispatch(ActionTypes.updateInterval.request('1m'));
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, dateDiffInDays, dateDiffInMonths]);

  useEffect(() => {
    const startTimeTimestamp = Math.trunc(getTimestampOf(new Date(startDate)));
    const endTimeTimestamp = Math.trunc(getTimestampOf(new Date(endDate)));

    setDateDiffInDays(differenceBetweenTwoDates(startTimeTimestamp, endTimeTimestamp, 'days'));
    setDateDiffInMonths(differenceBetweenTwoDates(startTimeTimestamp, endTimeTimestamp, 'months'));
  }, [startDate, endDate]);

  const dataPerformanceDownloadCSV = useMemo(() => {
    return performanceDataCSV({
      graph_data: type === 'store' ? storeGraphData : camGraphData,
      categories: type === 'store' ? storeCategories : camCategories,
    });
  }, [camCategories, camGraphData, storeGraphData, storeCategories, type]);

  if (loading) {
    return (
      <div className="animate-pulse w-full mt-3 bg-white p-4 rounded-lg shadow my-3">
        <div className="animate-bounce h-5 bg-gray-200 rounded-lg br-5 w-32 mb-2.5" />
        <div className="h-[400px] w-full bg-gray-200 rounded-lg br-5" />
      </div>
    );
  }

  return (
    <Row>
      <Col>
        <Card
          title="Performance"
          subTitle="The feature shows you profile view statistics"
          iconsGroup={
            <Tooltip tooltipText="Download" tooltipPlacement="top">
              {dataPerformanceDownloadCSV && (
                <DownloadCSV filename={`Performance Graph - ${intervalLabel}`} data={dataPerformanceDownloadCSV}>
                  <Icon icon="download" className="text-xl" />
                </DownloadCSV>
              )}
            </Tooltip>
          }
          actionsGroup={[
            {
              title: '10 Minutes',
              active: interval === '600s',
              onclick: () => dispatch(ActionTypes.updateInterval.request('600s')),
              disabled: dateDiffInDays >= 2,
              disabledHint: 'Please select a date range of 2 days or less',
            },
            {
              title: 'Hours',
              active: interval === '1h',
              onclick: () => dispatch(ActionTypes.updateInterval.request('1h')),
              disabled: dateDiffInDays >= 10,
              disabledHint: 'Please select a date range of 10 days or less',
            },
            {
              title: 'Days',
              active: interval === '1d',
              onclick: () => dispatch(ActionTypes.updateInterval.request('1d')),
              disabled: dateDiffInDays < 1 || dateDiffInDays >= 31,
              disabledHint:
                dateDiffInDays < 1
                  ? 'Please select a date range of 1 day or more'
                  : 'Please select a date range of 31 days or less',
            },
            {
              title: 'Weeks',
              active: interval === '1w',
              onclick: () => dispatch(ActionTypes.updateInterval.request('1w')),
              disabled: dateDiffInMonths >= 6 || dateDiffInDays <= 14,
              disabledHint:
                dateDiffInMonths >= 6
                  ? 'Please select a date range of 6 months or less'
                  : 'Please select a date range of 14 days or more',
            },
            {
              title: 'Months',
              active: interval === '1m',
              onclick: () => dispatch(ActionTypes.updateInterval.request('1m')),
              disabled: dateDiffInMonths <= 1,
              disabledHint: 'Please select a date range of 2 months or more',
            },
          ]}
        >
          <PerformancePlot
            categories={type === 'store' ? storeCategories : camCategories}
            graphData={type === 'store' ? storeGraphData : camGraphData}
            performancePlotData={performancePlotData}
            performancePlotLayout={performancePlotLayout}
            graphsPrefix={type === 'store' ? 'store' : 'cam'}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default PerformanceGraph;
